import create from "zustand"

const useFavoriteListsStore = create((set) => ({
  likedPhotoCount: 0,
  likedPhoto: null,

  setLikedPhoto: (likedPhoto) => set(() => ({ likedPhoto })),
  initLikedPhotoCount: (value) =>
    set(() => {
      return { likedPhotoCount: value }
    }),
  decrementLikedPhotoCount: () => set(({ likedPhotoCount }) => ({ likedPhotoCount: Math.max(likedPhotoCount - 1, 0) })),
  incrementLikedPhotoCount: () => set(({ likedPhotoCount }) => ({ likedPhotoCount: likedPhotoCount + 1 })),
}))

export { useFavoriteListsStore }
